<template>
  <div class="index_third">
    <img src="~img/indexImg/003.png" class="third_bg_img" />
    <div
      class="pc_box_img box_1"
      :class="{ box_1_animation: Animation }"
      ref="box1"
      @mouseover="mouseOver(1)"
      @mouseleave="mouseLeave(1)"
      :style="styles"
      @click="$router.push({ path: '/mouList?dataId=112&istitle=周易' })"
    >
      <img src="~img/indexImg/008.png" class="bg_img" />
      <img src="~img/indexImg/icon@2x.png" class="icon_img" v-if="!styles" @mouseover="mouseOver(1)" @mouseleave="mouseLeave(1)" />
      <img src="~img/indexImg/icon@2x(6).png" class="icon_img" v-if="styles" @mouseover="mouseOver(1)" @mouseleave="mouseLeave(1)" />
      <h3>"周易"</h3>
      <h4>NPU</h4>
      <div class="btn"></div>
      <p>查看详情</p>
      <i class="el-icon-arrow-right"></i>
    </div>
    <div
      class="pc_box_img box_2"
      :class="{ box_2_animation: Animation }"
      @mouseover="mouseOver(2)"
      @mouseleave="mouseLeave(2)"
      :style="styles2"
      @click="$router.push({ path: '/mouList?dataId=113&istitle=山海' })"
    >
      <img src="~img/indexImg/008.png" />
      <img src="~img/indexImg/icon@2x (1).png" class="icon_img" v-if="!styles2" @mouseover="mouseOver(2)" @mouseleave="mouseLeave(2)" />
      <img src="~img/indexImg/icon@2x(4).png" class="icon_img" v-if="styles2" @mouseover="mouseOver(2)" @mouseleave="mouseLeave(2)" />
      <h3>"山海"</h3>
      <h4>SPU</h4>
      <div class="btn"></div>
      <p>查看详情</p>
      <i class="el-icon-arrow-right"></i>
    </div>
    <div
      class="pc_box_img box_3"
      :class="{ box_3_animation: Animation }"
      @mouseover="mouseOver(3)"
      @mouseleave="mouseLeave(3)"
      :style="styles3"
      @click="$router.push({ path: '/mouList?dataId=111&istitle=星辰' })"
    >
      <img src="~img/indexImg/008.png" />
      <img src="~img/indexImg/icon@2x (2).png" class="icon_img" v-if="!styles3" @mouseover="mouseOver(3)" @mouseleave="mouseLeave(3)" />
      <img src="~img/indexImg/icon@2x(7).png" class="icon_img" v-if="styles3" @mouseover="mouseOver(3)" @mouseleave="mouseLeave(3)" />
      <h3>"星辰"</h3>
      <h4>CPU</h4>
      <div class="btn"></div>
      <p>查看详情</p>
      <i class="el-icon-arrow-right"></i>
    </div>
    <div
      class="pc_box_img box_4"
      :class="{ box_4_animation: Animation }"
      @mouseover="mouseOver(4)"
      @mouseleave="mouseLeave(4)"
      :style="styles4"
      @click="$router.push({ path: '/mouList?dataId=114&istitle=玲珑' })"
    >
      <img src="~img/indexImg/008.png" />
      <img src="~img/indexImg/icon@2x (3).png" class="icon_img" v-if="!styles4" @mouseover="mouseOver(4)" @mouseleave="mouseLeave(4)" />
      <img src="~img/indexImg/icon@2x(5).png" class="icon_img" v-if="styles4" @mouseover="mouseOver(4)" @mouseleave="mouseLeave(4)" />
      <h3>"玲珑"</h3>
      <h4>VPU & DPU & ISP</h4>
      <div class="btn"></div>
      <p>查看详情</p>
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'third',
  data() {
    return {
      Animation: '',
      styles: '',
      styles2: '',
      styles3: '',
      styles4: '',
    }
  },
  props: ['index'],
  created() {
    if (this.index == 2 || this.index == 3) {
      this.Animation = true
    } else {
      this.Animation = false
    }
  },
  watch: {
    index: function(index) {
      if (index == 2 || index == 3) {
        this.Animation = true
      } else {
        this.Animation = false
      }
    },
  },
  methods: {
    mouseOver(num) {
      if (num == 1) {
        this.styles = 'top:48%'
      } else if (num == 2) {
        this.styles2 = 'top:52%;transition: 0.5s all linear'
      } else if (num == 3) {
        this.styles3 = 'top:48%;transition: 0.5s all linear'
      } else if (num == 4) {
        this.styles4 = 'top:52%;transition: 0.5s all linear'
      }
    },
    mouseLeave(num) {
      if (num == 1) {
        this.styles = ''
      } else if (num == 2) {
        this.styles2 = ''
      } else if (num == 3) {
        this.styles3 = ''
      } else if (num == 4) {
        this.styles4 = ''
      }
    },
    zouyi() {
      window.open('https://www.armchina.com/ZhouYi.html', '_blank')
    },
    shanhai() {
      this.$router.push({ path: '/mouList?dataId=22' })
    },
    xingchen() {
      window.open('https://www.armchina.com/product_cpu.html', '_blank')
    },
    linglong() {
      this.$router.push({ path: '/mouList?dataId=23' })
    },
  },
}
</script>

<style lang="less" scoped>
.index_third {
  .third_bg_img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .pc_box_img {
    width: 3.97rem /* 397/100 */;
    position: absolute;
    cursor: pointer;
    // background-color: skyblue;
    .bg_img {
      width: 100%;
    }
    .icon_img {
      position: absolute;
      width: 1.61rem /* 161/100 */;
      top: 0.71rem /* 71/100 */;
      left: 50%;
      transform: translate(-50%);
      cursor: pointer;
    }
    h3 {
      font-size: 0.68rem /* 68/100 */;
      color: #ffffff;
      position: absolute;
      top: 2.5rem;
      left: 50%;
      transform: translate(-50%);
      border-bottom: 0.02rem solid #aaabab;
      opacity: 0.9;
    }
    h4 {
      font-size: 0.3rem /* 30/100 */;
      color: #00b3d6;
      position: absolute;
      top: 4rem /* 412/100 */;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      text-align: center;
      // font-family: Verdana;
    }
    .btn {
      width: 1.87rem /* 187/100 */;
      height: 0.5rem /* 50/100 */;
      border: 1px solid #ffffff;
      opacity: 0.3;
      position: absolute;
      top: 5rem;
      left: 50%;
      transform: translate(-50%);
      cursor: pointer;
    }
    p {
      color: #ffffff;
      font-size: 0.2rem /* 20/100 */;
      position: absolute;
      top: 5.1rem;
      left: 45%;
      transform: translate(-50%);
      cursor: pointer;
    }
    .el-icon-arrow-right {
      color: #ffffff;
      font-size: 0.24rem /* 20/100 */;
      position: absolute;
      top: 5.15rem;
      left: 65%;
      transform: translate(-50%);
      cursor: pointer;
    }
  }
  .box_1 {
    top: 20rem /* 200/100 */;
    left: 0.8rem /* 139/100 */;
    transition: 0.5s all;
  }
  .box_1_animation {
    top: 50%;
    transform: translate(0, -50%);
  }
  .box_2 {
    top: 20rem /* 122/100 */;
    left: 5.35rem /* 541/100 */;
    transition: 0.5s 0.2s;
  }
  .box_2_animation {
    top: 55%;
    transform: translate(0, -50%);
  }
  .box_3 {
    top: 20rem /* 200/100 */;
    right: 5.35rem /* 535/100 */;
    transition: 0.5s 0.3s all;
  }
  .box_3_animation {
    top: 50%;
    transform: translate(0, -50%);
  }
  .box_4 {
    top: 20rem;
    right: 0.87rem /* 87/100 */;
    transition: 0.5s 0.4s all;
  }
  .box_4_animation {
    top: 55%;
    transform: translate(0, -50%);
  }
}
</style>
