<template>
  <div class="web-third">
    <img src="~img/indexImg/018.png" class="web_bg_img" />
    <div class="web_box box1" :class="{ box1_animation: Animation }" @click="$router.push({ path: '/mouList?dataId=112&istitle=周易' })">
      <img src="~img/indexImg/008.png" class="web_bgimg" />
      <img src="~img/indexImg/icon@2x.png" class="web-icon" />
      <h3>"周易"</h3>
      <h4>NPU</h4>
      <div class="btn">
        <p>查看详情 ></p>
      </div>
    </div>
    <div class="web_box box2" :class="{ box2_animation: Animation }" @click="$router.push({ path: '/mouList?dataId=113&istitle=山海' })">
      <img src="~img/indexImg/008.png" class="web_bgimg" />
      <img src="~img/indexImg/icon@2x (1).png" class="web-icon" />
      <h3>"山海"</h3>
      <h4>SPU</h4>
      <div class="btn">
        <p>查看详情 ></p>
      </div>
    </div>
    <div class="web_box box3" :class="{ box3_animation: Animation }" @click="$router.push({ path: '/mouList?dataId=111&istitle=星辰' })">
      <img src="~img/indexImg/008.png" class="web_bgimg" />
      <img src="~img/indexImg/icon@2x (2).png" class="web-icon" />
      <h3>"星辰"</h3>
      <h4>CPU</h4>
      <div class="btn">
        <p>查看详情 ></p>
      </div>
    </div>
    <div class="web_box box4" :class="{ box4_animation: Animation }" @click="$router.push({ path: '/mouList?dataId=114&istitle=玲珑' })">
      <img src="~img/indexImg/008.png" class="web_bgimg" />
      <img src="~img/indexImg/icon@2x (3).png" class="web-icon" />
      <h3>"玲珑"</h3>
      <h4>VPU & DPU & ISP</h4>
      <div class="btn">
        <p>查看详情 ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webThird',
  props: ['webIndex'],
  data() {
    return {
      Animation: false,
    }
  },
  watch: {
    webIndex: function(webIndex) {
      if (this.webIndex == 2) {
        this.Animation = true
      } else {
        this.Animation = false
      }
    },
  },
  methods: {
    zouyi() {
      location.href = 'https://www.armchina.com/ZhouYi.html'
    },
    // xingchen() {
    //   location.href = "https://www.armchina.com/product_cpu.html";
    // }
  },
}
</script>

<style lang="less" scoped>
.web-third {
  height: 100vh;
  width: 100%;
  position: relative;
  .web_bg_img {
    height: 100vh;
  }
  .web_box {
    // background-color: hotpink;
    width: 7.8rem /* 312/40 */;
    height: 13.025rem /* 521/40 */;
    .web-icon {
      width: 3.5rem /* 140/40 */;
      position: absolute;
      left: 50%;
      top: 1.25rem /* 50/40 */;
      transform: translate(-50%);
    }
    h3 {
      font-size: 1.2rem /* 56/40 */;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 5.25rem /* 210/40 */;
      transform: translate(-50%);
      border-bottom: 2px solid #aaabab;
    }
    h4 {
      width: 100%;
      text-align: center;
      font-size: 0.65rem /* 26/40 */;
      color: #00b3d6;
      position: absolute;
      left: 50%;
      top: 7.75rem /* 310/40 */;
      transform: translate(-50%);
    }
    .btn {
      width: 4.325rem /* 173/40 */;
      height: 1.15rem /* 46/40 */;
      border: 1px solid #ffffff;
      position: absolute;
      top: 9.375rem /* 375/40 */;
      left: 50%;
      transform: translate(-50%);
      cursor: pointer;
      p {
        color: #ffffff;
        font-size: 0.5rem /* 20/40 */;
        line-height: 1.15rem /* 46/40 */;
        text-align: center;
      }
      // .el-icon-arrow-right {
      //   color: #ffffff;
      //   font-size: 0.35rem /* 14/40 */;
      //   cursor: pointer;
      //   float: right;
      //   line-height: 1.15rem /* 46/40 */;
      //   padding-right: 0.25rem /* 10/40 */;
      // }
    }
  }
  .box1 {
    position: absolute;
    top: 4.5rem /* 166/40 */;
    left: -6rem;
    transition: 0.5s all;
  }
  .box1_animation {
    left: 1.225rem /* 49/40 */;
  }
  .box2 {
    position: absolute;
    top: 4.5rem /* 166/40 */;
    right: -6rem /* 50/40 */;
    transition: 0.5s all;
  }
  .box2_animation {
    right: 1.25rem /* 50/40 */;
  }
  .box3 {
    position: absolute;
    top: 16rem;
    left: -6rem /* 49/40 */;
    transition: 0.5s 0.1s all;
  }
  .box3_animation {
    left: 1.225rem /* 49/40 */;
  }
  .box4 {
    position: absolute;
    top: 16rem /* 655/40 */;
    right: -6rem /* 50/40 */;
    transition: 0.5s 0.1s all;
  }
  .box4_animation {
    right: 1.25rem /* 50/40 */;
  }
}
</style>
