<template>
  <div class="index_three">
    <img src="~img/indexImg/021.png" class="three_bg_img" />
    <!-- <img src="~img/indexImg/007.png" class="logo" />
    <div class="shu">
      <div class="kuai" :class="{ kuai_animation: kuai }"></div>
    </div> -->
    <div class="video_box" v-show="play">
      <img src="~img/indexImg/36.png" alt="" class="sanjiao" />
      <div class="play_box">
        <img src="~img/indexImg/022.png" class="video_bgimg" />
        <img src="~img/indexImg/023.png" class="video_paly" @click="videoplay" />
        <p class="p1">PLAY</p>
        <p class="p2">VIDEO</p>
      </div>
    </div>

    <div class="video_dialog" v-show="videoShow">
      <img src="~img/indexImg/36.png" alt="" class="sanjiao" />

      <video controls="false" class="xuan_video" controlsList="nodownload noremoteplayback" ref="xc_video">
        <source src="../assets/video/HD1080~9.mp4" type="video/mp4" />
        您的浏览器不支持Video标签。
      </video>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import { clearInterval } from 'timers'
export default {
  name: 'second',
  components: {
    navig,
  },
  props: ['index', 'w'],
  data() {
    return {
      topAnimation: false,
      lowAnimation: false,
      play: true,
      videoShow: false,
      only: true,
      kuai: false,
      timer: null,
      timer2: null,
    }
  },
  created() {},
  watch: {
    index: function(index, oldindex) {
      console.log(index)
      if (index === 0 || index === 2) {
        this.close()
      }
      if (index != 2) {
        // 不在当前这个层 移除动画类
        this.lowAnimation = false
        this.topAnimation = false
        // this.video.pause();
      }
      if (index == 2 && index > oldindex) {
        this.topAnimation = true
        this.lowAnimation = false
      } else if (index == 1 && index < oldindex) {
        this.lowAnimation = true
        this.topAnimation = false
      }

      if (index == 2) {
        this.timer = setInterval(() => {
          this.kuai = true
        }, 2000)
        this.timer2 = setInterval(() => {
          this.kuai = false
        }, 4000)
      } else {
        this.kuai = false
        // clearInterval(this.timer);
        // clearInterval(this.timer2);
      }
      // console.log("上", this.topAnimation, "下", this.lowAnimation);
    },
  },
  mounted() {
    this.video = this.$refs.xc_video
    this.video['disablePictureInPicture'] = true
  },
  methods: {
    videoplay() {
      this.play = false
      this.videoShow = true
      this.video.play()
      // console.log("执行");
      // this.$emit("get", this.play);
    },
    close() {
      this.video.pause()
      // this.play = true;
      // this.videoShow = false;
      // this.$emit("get", this.play);
    },
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    window.clearInterval(this.timer2)
  },
}
</script>

<style lang="less" scoped>
.index_three {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  .three_bg_img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .logo {
    width: 1.969rem /* 196.9/100 */;
    position: absolute;
    left: 2.12rem /* 80/100 */;
    bottom: 4.21rem /* 421/100 */;
  }
  .shu {
    width: 1px;
    background: #676767;
    height: 3.07rem /* 347/100 */;
    position: absolute;
    left: 3.1rem /* 310/100 */;
    bottom: 0.71rem; /* 71/100 */
    .kuai {
      width: 1px;
      height: 0.71rem /* 71/100 */;
      background: #ffffff;
      position: absolute;
      opacity: 0;
    }
    .kuai_animation {
      animation: kuaiame 0.3s linear alternate;
    }
  }
  @keyframes kuaiame {
    0% {
      opacity: 1;
      top: 0%;
    }
    25% {
      opacity: 1;

      top: 25%;
    }
    50% {
      opacity: 1;

      top: 50%;
    }
    75% {
      opacity: 1;
      top: 75%;
    }
  }
  .video_dialog {
    position: absolute;
    height: 7.438rem;
    width: 12.885rem;
    // bottom: 0.72rem /* 72/100 */;
    // right: 0.81rem /* 81/100 */;
    // background: hotpink;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    i {
      position: absolute;
      top: 15%;
      right: 150px;
      font-size: 50px;
      color: #fff;
      cursor: pointer;
    }
    .modal {
      width: 100%;
      height: 100vh;
      opacity: 0.8;
      background-color: #000000;
    }
    .sanjiao {
      width: 3rem;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .xuan_video {
      position: absolute;
      height: 7.038rem;
      width: 12.485rem;
      // bottom: 0.72rem /* 72/100 */;
      // right: 0.81rem /* 81/100 */;
      // background: hotpink;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  .video_box {
    position: absolute;
    height: 7.238rem;
    width: 12.685rem;
    // bottom: 0.72rem /* 72/100 */;
    // right: 0.81rem /* 81/100 */;
    // background: hotpink;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    .sanjiao {
      width: 3rem;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .play_box {
      position: absolute;
      height: 7.038rem;
      width: 12.485rem;
      right: 0;
      top: 0;
      .video_bgimg {
        height: 100%;
        width: 100%;
      }
      .video_paly {
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
      p {
        font-size: 20px;
        color: #fff;
        font-weight: 400;
      }
      .p1 {
        position: absolute;
        top: 50%;
        left: 39.29%;
        transform: translate(0, -50%);
      }
      .p2 {
        position: absolute;
        top: 50%;
        right: 38.16%;
        transform: translate(0, -50%);
      }
    }
  }
}
.pc_top_video_animation {
  animation: topbig 1s linear alternate;
}
.pc_low_video_animation {
  animation: lowbig 1s linear alternate;
}
// 背景动画
@keyframes topbig {
  from {
    top: 200vh;
  }
  to {
    top: 0;
  }
}
@keyframes lowbig {
  from {
    top: -200vh;
  }
  to {
    top: 0;
  }
}
</style>
