<template>
  <div class="web_three">
    <main>
      <div class="img_box" @click="videoPlay">
        <div class="zhezhao" v-show="!play">
          <img src="~img/indexImg/32.png" class="bg_img" />
          <img src="~img/indexImg/023.png" class="video_play" />
          <p class="p1">PLAY</p>
          <p class="p2">VIDEO</p>
        </div>
        <video controls="false" class="xuan_video" ref="xc_video" v-show="play">
          <source src="~@/assets/video/HD1080~9.mp4" type="video/mp4" />
          您的浏览器不支持Video标签。
        </video>
        <img src="~img/indexImg/36.png" class="sanjiao" />
      </div>
      <!-- <img src="~img/indexImg/007.png"  class="logo_three"> -->
      <div class="xian">
        <div class="dian"></div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'webThree',
  data() {
    return {
      play: false,
    }
  },
  props: ['webIndex'],
  watch: {
    webIndex: function(webIndex) {
      console.log(webIndex)
      // this.play = false;
      if (webIndex == 0 || webIndex == 2) {
        this.clores()
      }
    },
  },
  mounted() {
    this.video = this.$refs.xc_video
  },
  methods: {
    videoPlay() {
      this.play = true
      this.video.play()
    },
    clores() {
      this.play = false
      this.video.pause()
    },
  },
}
</script>

<style lang="less" scoped>
.web_three {
  height: 100%;
  background: url('~img/indexImg/24.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 20%;
  position: relative;
  // .xuan_video {
  //   position: absolute;
  //   top: 90px;
  //   width: 100%;
  //   height: 43.8%;
  //   object-fit: cover;
  // }
  .logo_three {
    width: 27.41%;
    margin: 20px auto;
  }
  .xian {
    width: 1px;
    height: 100px;
    // margin: 20px auto;
    background: #676767;
    position: absolute;
    left: 50%;
    bottom: 9%;
    transform: (-50%);
    .dian {
      width: 1px;
      height: 20%;
      background-color: #fff;
    }
  }
  .img_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    // background-color: hotpink;
    .zhezhao {
      margin: auto;
      width: 94%;
      padding-bottom: 12px;
      position: relative;
      z-index: 99;
      .bg_img {
        width: 100%;
        height: 43.8%;
      }
      .video_play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem /* 160/40 */;
      }
      p {
        font-size: 10px;
        color: #fff;
        font-weight: 400;
      }
      .p1 {
        position: absolute;
        top: 50%;
        left: 27%;
        transform: translate(0, -50%);
      }
      .p2 {
        position: absolute;
        top: 50%;
        right: 25%;
        transform: translate(0, -50%);
      }
    }
    .xuan_video {
      display: block;
      margin: auto;
      width: 94%;
      padding-bottom: 12px;
      position: relative;
      z-index: 100;
    }
    .sanjiao {
      width: 74px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
